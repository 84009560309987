// 取得版本號
import packageConfig from '../../../package.json'
// api
import { authFb } from '@/methods/API/fbApi'

export const Login = {
  data() {
    return {
      login: {
        account: '',
        password: ''
      },
      checkLaw: {
        privacy: true,
        service: true
      },
      packageConfig: packageConfig
    }
  },
  created() {
    // 初始化
    this.initialization()
    console.log(window.history)
  },
  methods: {
    // 初始化
    initialization() {
      $('#htmlLoading').addClass('NShow')
      this.login = {
        account: '',
        password: ''
      }
      this.recordIdentity()
    },
    // 前往註冊
    goRegister() {
      this.$router.push('/ETwingo/serve?from=register')
    },
    // 紀錄身分 (顧客 / 賣家)
    recordIdentity() {
      if (this.$route.name === 'Login') window.localStorage.setItem('identity', 'seller')
      else window.localStorage.setItem('identity', 'customer')
    },
    // 系統登入
    systemLogin() {
      if (!this.checkLaw.privacy) return this.SweetAlert('other', '您尚未同意隱私權政策')
      if (!this.checkLaw.service) return this.SweetAlert('other', '您尚未同意服務條款')
      const vm = this
      const systemLoginApi = `${process.env.VUE_APP_API}/user/login`
      const data = new FormData()
      data.append('account', this.login.account)
      data.append('password', this.login.password)
      data.append('userType', 1)
      this.$methods.switchLoading('show')
      this.recordIdentity()
      $.ajax({
        type: 'POST',
        async: true,
        url: systemLoginApi,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const token = res.data
            vm.$methods.setCookie('serverToken', token)
            console.log(vm.$methods.go("normal"))
            vm.$router.push(vm.$methods.go("normal"))
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 登入 FB
    FbLogin() {
      if (!this.checkLaw.privacy) return this.SweetAlert('other', '您尚未同意隱私權政策')
      if (!this.checkLaw.service) return this.SweetAlert('other', '您尚未同意服務條款')
      this.recordIdentity()
      authFb("login")
    },
    // Line 登入
    loginWithLine() {
      if (!this.checkLaw.privacy) return this.SweetAlert('other', '您尚未同意隱私權政策')
      if (!this.checkLaw.service) return this.SweetAlert('other', '您尚未同意服務條款')
      this.recordIdentity()
      const loginId = process.env.VUE_APP_LINELOGIN_ID;
      const loginCallback = process.env.VUE_APP_LINELOGIN_CALLBACK;
      const scopes = "profile%20openid%20email";
      const loginRequestAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${loginId}&redirect_uri=${loginCallback}&scope=${scopes}&state=login&bot_prompt=aggressive`;
      window.location = loginRequestAuthUrl;
    },
    // 前往 官方網站
    goTeachingPage() {
      window.open("https://e.twingo.com.tw/?page_id=4315", "_blank");
    },
  },
}